<template>
  <div>
    <v-row justify="center" class="px-4 py-4" no-gutters>

      <v-col cols="4" class="px-2" :my-5="$vuetify.breakpoint.mdAndUp">
        <v-card :class="$vuetify.breakpoint.mdAndUp ? 'pa-3' : 'pa-3'" flat>
          <v-row no-gutters>
            <v-col cols="12" v-if="$store.getters['user/user'].user.isVerified">
              <!-- <v-col cols="12" class="pa-2 primary text-center">
                <v-icon small class="pr-2 text-center" color="white">error</v-icon>
                <span class="white--text text-caption">The task will be automatically removed if no lawyer is selected after 7 days.</span>
              </v-col> -->
              <p :class="$vuetify.breakpoint.mdAndUp ? 'text-md-h6 font-weight-medium' : 'text-xs-h5 font-weight-medium'">Post Issue</p>
              <!-- <p class="mb-2 body-2">
                If you need legal advice about a problem, ask a lawyer. Post your question and its details below.
                The lawyers will be able to read the subject and the first few lines of the details.
                They will then submit a proposal containing their required legal fee as well as a brief description of themselves and how they will tackle the issue.
                You get to decide the lawyer you want to interact with and he/she will be able to read the entire details of your problem. 
              </p> -->
            </v-col>
            <v-col v-if="$store.getters['user/user'].user.isVerified" cols="12" class="mb-0">
              <v-form ref="form" class="">
                <v-text-field
                  :class="$vuetify.breakpoint.smAndDown ? 'body-2' : ''"
                  :dense="$vuetify.breakpoint.smAndDown"
                  :rules="legalAdviceTitle"
                  placeholder="Subject/Main Issue... "
                  counter="255"
                  v-model="title"
                  outlined
                ></v-text-field>
                <v-textarea
                  :dense="$vuetify.breakpoint.smAndDown"
                  :class="$vuetify.breakpoint.smAndDown ? 'body-2' : ''"
                  :rules="legalAdviceDescription"
                  placeholder="Tell us more about your problem... "
                  counter="3000"
                  v-model="description"
                  outlined
                  auto-grow
                ></v-textarea>
                <!-- <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'" class="pr-0 pl-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-select
                          :dense="$vuetify.breakpoint.smAndDown"
                          :items="limitDays"
                          :rules="limitDaysRules"
                          v-model="daysToFinish"
                          outlined
                          hide-details="auto"
                        >
                          <div slot="label">
                            <span class="body-2">Time Frame</span>
                          </div>
                        </v-select>
                      </div>
                    </template>
                    <span>Required number of days to complete the task.</span>
                  </v-tooltip>
                </v-col> -->
              </v-form>
            </v-col>
            <v-row v-if="$store.getters['user/user'].user.isVerified" align="center" no-gutters>
                <v-col cols="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-checkbox v-on="on" v-model="isAnonymous" class="mt-2 pb-3" hide-details>
                        <div slot="label">
                          <span class="body-2">Hide your name and contact details.</span>
                        </div>
                      </v-checkbox>
                    </template>
                      <span>Click here if you wish to be anonymous</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="onPostQuestion"
                    :loading="btnLoading"
                    large
                    dark
                    depressed
                    block
                    color="primary"
                  >Post Question</v-btn>
                </v-col>
                <!-- <v-col>
                  <v-divider class="mt-4 mb-3"></v-divider>
                  <ul>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">Seek legal help by posting your question for free.</li>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">You will receive proposals from lawyers offering to give advice and stating their rates. (Some lawyers will answer your questions at no cost.)</li>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">Choose the lawyer you feel is best suited for the job.</li>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">Pay the lawyer through us using the different modes described in our platform. We will not release the payment until the task is completed.</li>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">Once payment is sent to us, the lawyer will begin to answer your question.</li>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">If satisfied, click on the Complete button and we will forward the payment to the lawyer.</li>
                    <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">If you are not satisfied or do not feel comfortable with the lawyer, you can file a dispute and we will review the exchanges and determine if payment should be made to the lawyer.</li>
                  </ul>
                </v-col> -->
            </v-row>
            <v-col cols="12" py-3 v-else class="text-center">
              <v-sheet class="pa-2">
                <span class="text-center subheading font-weight-medium">You can only post questions if Email is already verified</span>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="4" class="px-5">
        <!-- <v-col cols="12" class="pa-2 primary text-center">
          <v-icon small class="pr-2 text-center" color="white">error</v-icon>
          <span class="white--text text-caption">The task will be automatically removed if no lawyer is selected after 7 days.</span>
        </v-col> -->

        <v-col cols="12" class="text-center">
          <img width="90%" src="https://cdn.logojoy.com/wp-content/uploads/20190423164609/Asset-22%404x.png" />
        </v-col>

        

        <v-col cols="12" class="pb-0 px-1">
          <p class="mb-0 body-2 justifyText">
            If you need legal advice about a problem, ask a lawyer. Post your question and its details below.
            The lawyers will be able to read the subject and the first few lines of the details.
            They will then submit a proposal containing their required legal fee as well as a brief description of themselves and how they will tackle the issue.
            You get to decide the lawyer you want to interact with and he/she will be able to read the entire details of your problem. 
          </p>
        </v-col>

          <v-col cols="12" class="px-0">
          <v-divider class="mt-2 mb-5 mx-1"></v-divider>
          <ul class="mx-0 px-4">
            <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">Seek legal help by posting your question for free.</li>
            <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">You will receive proposals from lawyers offering to give advice and stating their rates. (Some lawyers will answer your questions at no cost.)</li>
            <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">Choose the lawyer you feel is best suited for the job.</li>
            <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">Pay the lawyer with both party's agreed payment options. We will not release the payment until the task is completed.</li>
            <!-- <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">Once payment is sent to us, the lawyer will begin to answer your question.</li> -->
            <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">If satisfied, click on the Complete button and rate the lawyer.</li>
            <!-- <li :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2 justifyText'">If you are not satisfied or do not feel comfortable with the lawyer, you can file a dispute and we will review the exchanges and determine if payment should be made to the lawyer.</li> -->
          </ul>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import io from 'socket.io-client'
import rulesMixin from '@/mixins/rulesMixin'
import ClientProblemService from '@/services/ClientProblemService'

export default {
  mixins: [
    rulesMixin
  ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isAnonymous: false,
      btnLoading: false,
      title: undefined,
      description: undefined,
      daysToFinish: 0,
      limitDays: [ 1, 2, 3, 4, 5, 6, 7 ],
      limitDaysRules: [ (v) => !!v || 'Please enter days here.' ],
      legalAdviceTitle: [
        (v) => !!v || 'Please type your main question here.',
        (v) => (v && v.length >= 10) || 'Please enter at least 10 characters.',
        (v) => (v && v.length <= 255) || 'Please enter at most 255 characters.'
      ],
      legalAdviceDescription: [
        (v) => !!v || 'Please write down the details of your problem.',
        (v) => (v && v.length >= 30) || 'Please enter at least 30 characters.',
        (v) => (v && v.length <= 3000) || 'Please enter at most 3000 characters.'
      ]
    }
  },
  methods: {
    onPostQuestion () {
      if (this.$refs.form.validate()) {
        this.btnLoading = true
        ClientProblemService.postLegalAdvice({
          isAnonymous: this.isAnonymous,
          problemType: 'Legal Advice',
          title: this.title,
          description: this.description
        })
          .then(res => {
            this.$refs.form.reset()
            this.$swal({
              type: 'success',
              text: 'Your task has been posted. Please wait for proposals from the lawyers.',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })

            this.socket.emit('post-task', res.data)

            this.$router.push(`/jobs/${res.data._id}`)
          })
          .catch(err => {
            this.$swal({
              type: 'error',
              title: err.response ? 'Opps!' : err.message,
              text: err.response ? err.response.data : '',
              showConfirmButton: false,
              showCloseButton: true
            })
          })
          .finally(() => {
            this.btnLoading = false
          })
      }
    }
  }
}
</script>

<style>

</style>
